<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">{{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[72]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-12">
            <div class="m_sidebar">
              <div class="m_sidebar_title">
                {{$store.state.language.lang[3]}}
              </div>
              <div class="m_sidebar_link">
                <router-link to="/news">
                  {{$store.state.language.lang[69]}}</router-link>
              </div>

              <div class="m_sidebar_link">
                <router-link to="/press_secretary">
                  {{$store.state.language.lang[71]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/press_releases">
                  {{$store.state.language.lang[72]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/tenders">
                  {{$store.state.language.lang[74]}}</router-link>
              </div>
              <div class="m_sidebar_link">
                <router-link to="/media">
                  {{$store.state.language.lang[70]}}</router-link>
              </div>
            </div>

          </div>

          <div class="col-xl-9 col-12">
            <div class="m_body_title">
              <h2>{{$store.state.language.lang[72]}}</h2>
            </div>
            <MediaSideNews :name="$store.state.language.lang[72]"></MediaSideNews>
            <div>
              <div class="dec_input_group">
                <div class="search_inputs">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9.09091 1.81818C5.07429 1.81818 1.81818 5.07429 1.81818 9.09091C1.81818 13.1075 5.07429 16.3636 9.09091 16.3636C13.1075 16.3636 16.3636 13.1075 16.3636 9.09091C16.3636 5.07429 13.1075 1.81818 9.09091 1.81818ZM0 9.09091C0 4.07014 4.07014 0 9.09091 0C14.1117 0 18.1818 4.07014 18.1818 9.09091C18.1818 11.2739 17.4124 13.2771 16.1299 14.8443L19.7337 18.4481C20.0888 18.8031 20.0888 19.3787 19.7337 19.7337C19.3787 20.0888 18.8031 20.0888 18.4481 19.7337L14.8443 16.1299C13.2771 17.4124 11.2739 18.1818 9.09091 18.1818C4.07014 18.1818 0 14.1117 0 9.09091Z"
                          fill="#01011C"/>
                  </svg>
                  <input type="text" :placeholder="$store.state.language.lang[65]">
                </div>
                <div class="buttons_search">
                  <button>Search</button>
                </div>
              </div>
            </div>
            <div class="dec_text">
              {{ $store.state.language.lang[66] }}: {{$store.state.docs3.total}}
            </div>
            <div class="dec_card" v-for="(item,index) in $store.state.docs3.documents" :key="index">
              <div class="dec_card_main">
                <div class="loga_dec">
                  <img src="../../assets/img/Subtract.png" alt="">
                </div>
                <div class="title_dec">
                  <div class="dec_title">{{item.title}}</div>
                  <div class="dec_num">№ {{item.document_number}}</div>
                </div>

              </div>
              <div class="download">
                <a :href="item.file">
                  <div class="link_docs">
                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.084 13.667C21.084 13.2528 20.7482 12.917 20.334 12.917C19.9198 12.917 19.584 13.2528 19.584 13.667H21.084ZM2.41797 13.667C2.41797 13.2528 2.08218 12.917 1.66797 12.917C1.25376 12.917 0.917969 13.2528 0.917969 13.667H2.41797ZM17.2793 9.80733C17.5722 9.51444 17.5722 9.03957 17.2793 8.74667C16.9864 8.45378 16.5115 8.45378 16.2186 8.74667L17.2793 9.80733ZM11.036 14.99L10.5056 15.5203C10.6463 15.661 10.8371 15.74 11.036 15.74C11.2349 15.74 11.4256 15.661 11.5663 15.5203L11.036 14.99ZM5.8533 8.74667C5.56041 8.45378 5.08553 8.45378 4.79264 8.74667C4.49975 9.03957 4.49975 9.51444 4.79264 9.80733L5.8533 8.74667ZM11.786 1.677C11.786 1.26279 11.4502 0.927002 11.036 0.927002C10.6218 0.927002 10.286 1.26279 10.286 1.677H11.786ZM10.286 14.973C10.286 15.3872 10.6218 15.723 11.036 15.723C11.4502 15.723 11.786 15.3872 11.786 14.973H10.286ZM19.584 13.667V16.714H21.084V13.667H19.584ZM19.584 16.714C19.584 17.9568 18.5768 18.964 17.334 18.964V20.464C19.4052 20.464 21.084 18.7852 21.084 16.714H19.584ZM17.334 18.964H4.66797V20.464H17.334V18.964ZM4.66797 18.964C3.42518 18.964 2.41797 17.9568 2.41797 16.714H0.917969C0.917969 18.7852 2.59675 20.464 4.66797 20.464V18.964ZM2.41797 16.714V13.667H0.917969V16.714H2.41797ZM16.2186 8.74667L10.5056 14.4597L11.5663 15.5203L17.2793 9.80733L16.2186 8.74667ZM11.5663 14.4597L5.8533 8.74667L4.79264 9.80733L10.5056 15.5203L11.5663 14.4597ZM10.286 1.677V14.973H11.786V1.677H10.286Z" fill="#00B57F"/>
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../Header/Navbar";
import Footer from "../Header/Footer";
import MediaSideNews from "../MediaSideNews";

export default {
  components: {
    MediaSideNews,
    Footer,
    Navbar
  },
  data() {
    return {
      isActive_m: false,
      searchs:''
    }
  },
  mounted() {
    this.$store.dispatch('docs3')
  },
  methods: {
    getSearch(){
      this.$store.state.docsn1=[]
      let a=""
      if(isNaN(this.searchs)==false){
        a={
          "name":`number=${this.searchs}`
        }
      }

      if(isNaN(this.searchs)==true){
        a={

          "name": `doc_name=${this.searchs}`
        }
      }

      this.$store.dispatch('docs1',a)
    }
  }
}
</script>
