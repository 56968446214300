<template>
  <div class="media_sidebar" >
    <div class="media_sidebar_child" :class="{'active':isActive}" @click="isActive=!isActive" >
      <div>
        {{name}}
      </div>
      <div class="media_icons" :class="{'active':isActive}" @click="isActive=!isActive" >
        <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_831_12617)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3364 5.81497C18.5733 5.95456 18.6145 6.21174 18.4283 6.38939L12.4283 12.1167C12.3249 12.2154 12.1668 12.273 11.9994 12.273C11.8321 12.273 11.674 12.2154 11.5705 12.1167L5.57055 6.38939C5.38443 6.21174 5.42558 5.95456 5.66245 5.81497C5.89933 5.67538 6.24223 5.70625 6.42835 5.8839L11.9994 11.2018L17.5705 5.8839C17.7567 5.70625 18.0996 5.67538 18.3364 5.81497Z" fill="#01011C"/>
          </g>
          <defs>
            <clipPath id="clip0_831_12617">
              <rect width="18" height="24" fill="white" transform="translate(24) rotate(90)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
    <div class="media_sidebar_menus"  :class="{'active':isActive}" >
      <div class="media_sidebar_menus_child">
        <div class="m_sidebar_link" v-for="(items,indexx) in mediaSide" :key="indexx" >
          <router-link :to="items.link"  >{{items.menu}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:['name'],
  data(){
    return{
      isActive:false,
      mediaSide:[
        {
          menu:'Новости',
          link:'/news',
          active:0
        },
        {
          menu:'Пресс-секретарь',
          link:'/press_secretary',
          active:0
        },
        {
          menu:'Пресс-релизы',
          link:'/press_releases',
          active:0
        },
        {
          menu:'Тендеры',
          link:'/tenders',
          active:0
        },
        {
          menu:'Медиатека',
          link:'/media',
          active:0
        },


      ]
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
